import React, { useState } from "react"
import styled from "styled-components"

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.colorOne || "rgba(60, 80, 90, 0.8)"};
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 99999;
`

const CloseButton = styled.button`
  background: transparent;
  color: ${props => props.colorTwo || "white"};
  font-size: 5rem;
  border: 0;
  position: absolute;
  z-index: 6;
  right: -4rem;
  top: -5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  @media (max-width: 400px) {
    visibility: hidden;
  }
`

const ModalContents = styled.div`
  position: relative;
`

const Modal = ({ open, children, handleClose, colorOne, colorTwo }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <>
      {open && (
        <StyledModal
          onClick={() => {
            if (!hovered) handleClose()
          }}
          color={colorOne}
        >
          <ModalContents
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {children}
            <CloseButton
              onClick={handleClose}
              color={colorTwo}
              data-test="closeButton"
            >
              x
            </CloseButton>
          </ModalContents>
        </StyledModal>
      )}
    </>
  )
}

export default Modal
