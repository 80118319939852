import styled from "styled-components"

const StyledButton = styled.button`
  width: ${(props) => props.width || "100%"};
  min-height: 40px;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  background: ${(props) =>
    `linear-gradient(to left, ${props.colorTwo} 50%, #25282B 50%)`};
  background-size: 200% 100%;
  background-position: right bottom;
  transition: 0.3s all cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.1s;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1em;
  padding: 6px 16px;
  outline: none;
  &:hover {
    color: ${(props) => props.colorOne};
    background-position: left bottom;
    border-radius: 16px;
    border: ${(props) => `1px solid ${props.colorOne}`};
  }
`

export default StyledButton
