import React from "react"
import styled from "styled-components"

const StyledHamburger = styled.div`
  height: 20px;
  width: 32px;
  display: grid;
  align-content: space-between;
  cursor: pointer;

  &:hover {
    i {
      background-position: left bottom;
    }
  }

  i {
    width: 100%;
    height: 3px;
    background: ${props => props.colorOne || "#EDEDED"};
    transition: transform 0.3s cubic-bezier(0.51, 0.02, 0.58, 0.39);
  }

  ${props =>
    props.open
      ? `
      transform: rotate(45deg);
      
      i:nth-child(1) {
        background: ${props.colorTwo || "#2D97A0"};
        transform: translateY(10px) rotate(180deg);
      };
      i:nth-child(2) {
        opacity: 0;
      };
      i:nth-child(3) { 
        background: ${props.colorTwo || "#2D97A0"};
        transform: translateY(-7px) rotate(90deg);
      };
    `
      : null}
`

const HamburgerIcon = ({ handleClick, open, colorOne, colorTwo }) => {
  return (
    <StyledHamburger
      open={open}
      onClick={handleClick}
      colorOne={colorOne}
      colorTwo={colorTwo}
    >
      <i />
      <i />
      <i />
    </StyledHamburger>
  )
}

export default HamburgerIcon
