import styled from "styled-components"

const CenteredDiv = styled.div`
  height: 100%;
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
`

export default CenteredDiv
