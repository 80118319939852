import React from "react"
import Modal from "../Modal"
import { FullScreenModalContent } from "./PrivacyPolicy"

const TermsAndConditions = ({ handleClose }) => (
  <Modal colorOne="black" open={true} handleClose={handleClose}>
    <FullScreenModalContent>
      <h2>San Diego Craft Distillers Guild Terms of Service</h2>
      <p>
        1. Terms By accessing the website at sddistillersguildfest.com, you are
        agreeing to be bound by these terms of service, all applicable laws and
        regulations, and agree that you are responsible for compliance with any
        applicable local laws. If you do not agree with any of these terms, you
        are prohibited from using or accessing this site. The materials
        contained in this website are protected by applicable copyright and
        trademark law.
        <br />
        2. Use License
        <br />
        Permission is granted to temporarily download one copy of the materials
        (information or software) on San Diego Craft Distillers Guild's website
        for personal, non-commercial transitory viewing only. This is the grant
        of a license, not a transfer of title, and under this license you may
        not:
        <br />
        i. modify or copy the materials;
        <br />
        ii. use the materials for any commercial purpose, or for any public
        display (commercial or non-commercial);
        <br />
        iii. attempt to decompile or reverse engineer any software contained on
        San Diego Craft Distillers Guild's website;
        <br />
        iv. remove any copyright or other proprietary notations from the
        materials; or
        <br />
        v. transfer the materials to another person or "mirror" the materials on
        any other server.
        <br />
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by San Diego Craft Distillers Guild
        at any time. Upon terminating your viewing of these materials or upon
        the termination of this license, you must destroy any downloaded
        materials in your possession whether in electronic or printed format.
        <br />
        3. Disclaimer
        <br />
        a. The materials on San Diego Craft Distillers Guild's website are
        provided on an 'as is' basis. San Diego Craft Distillers Guild makes no
        warranties, expressed or implied, and hereby disclaims and negates all
        other warranties including, without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or
        non-infringement of intellectual property or other violation of rights.
        <br />
        b. Further, San Diego Craft Distillers Guild does not warrant or make
        any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its website or otherwise
        relating to such materials or on any sites linked to this site.
        <br />
        4. Limitations
        <br />
        In no event shall San Diego Craft Distillers Guild or its suppliers be
        liable for any damages (including, without limitation, damages for loss
        of data or profit, or due to business interruption) arising out of the
        use or inability to use the materials on San Diego Craft Distillers
        Guild's website, even if San Diego Craft Distillers Guild or a San Diego
        Craft Distillers Guild authorized representative has been notified
        orally or in writing of the possibility of such damage. Because some
        jurisdictions do not allow limitations on implied warranties, or
        limitations of liability for consequential or incidental damages, these
        limitations may not apply to you.
        <br />
        5. Accuracy of materials
        <br />
        The materials appearing on San Diego Craft Distillers Guild's website
        could include technical, typographical, or photographic errors. San
        Diego Craft Distillers Guild does not warrant that any of the materials
        on its website are accurate, complete or current. San Diego Craft
        Distillers Guild may make changes to the materials contained on its
        website at any time without notice. However San Diego Craft Distillers
        Guild does not make any commitment to update the materials.
        <br />
        6. Links
        <br />
        San Diego Craft Distillers Guild has not reviewed all of the sites
        linked to its website and is not responsible for the contents of any
        such linked site. The inclusion of any link does not imply endorsement
        by San Diego Craft Distillers Guild of the site. Use of any such linked
        website is at the user's own risk.
        <br />
        7. Modifications
        <br />
        San Diego Craft Distillers Guild may revise these terms of service for
        its website at any time without notice. By using this website you are
        agreeing to be bound by the then current version of these terms of
        service.
        <br />
        8. Governing Law
        <br />
        These terms and conditions are governed by and construed in accordance
        with the laws of California and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
        <br />
        Generated by GetTerms.io
      </p>
    </FullScreenModalContent>
  </Modal>
)

export default TermsAndConditions
