import React, { useState } from "react"
import Footer from "./Footer"
import PrivacyPolicy from "./FunStuff/PrivacyPolicy"
import TermsAndConditions from "./FunStuff/TermsAndConditions"
import { themeStyles } from "./Page"

const FooterComponent = () => {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)

  console.log({ privacyPolicyOpen, termsOpen })

  return (
    <Footer
      copywright="© 2023 | San Diego Distiller's Guild"
      backgroundImg="https://res.cloudinary.com/a1818/image/upload/q_auto,w_auto,w_auto/v1550341859/sdDistillers/sanDiegoCoast.jpg"
      breakpoint={themeStyles.mobileBreakPoint}
      instagram="https://www.instagram.com/sddistillersguild/"
      facebook="https://www.facebook.com/SDDistillersGuild/"
      color="white"
      backgroundColor="black"
    >
      <p style={{ cursor: "pointer" }} onClick={() => setTermsOpen(true)}>
        Terms & Conditions
      </p>
      <p
        style={{ cursor: "pointer" }}
        onClick={() => setPrivacyPolicyOpen(true)}
      >
        Cookies & Privacy Policy
      </p>
      <img
        style={{ width: "20vw", minWidth: "150px" }}
        src="https://res.cloudinary.com/a1818/image/upload/q_auto,w_auto,w_auto/v1550342752/sdDistillers/sdDistillersGuild.svg"
        alt="San Diego Distiller's Guild Logo"
      />
      {privacyPolicyOpen && (
        <PrivacyPolicy handleClose={() => setPrivacyPolicyOpen(false)} />
      )}
      {termsOpen && (
        <TermsAndConditions handleClose={() => setTermsOpen(false)} />
      )}
    </Footer>
  )
}

export default FooterComponent
