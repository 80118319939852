import React, { useState } from "react"
import styled from "styled-components"
import { distilleryData } from "../distilleryData"
import ContentSection from "./styles/ContentSection"
import CenteredDiv from "./styles/CenteredDiv"
import Modal from "./Modal"
import { themeStyles } from "./Page"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const DistilleryLogoSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 80vw;
  grid-gap: 5vh;
  padding: 10vh 0;
`

const StyledLogo = styled.div`
  width: ${(props) => (props.featured ? "300px" : "200px")};
  max-height: 200px;
  display: grid;
  align-items: center;
  picture {
    img {
      object-fit: contain;
      cursor: pointer;
      background: white;
      transition: transform 0.3s;
      &:hover {
        transform: scale(0.95);
      }
    }
  }
`

const DistilleryLogo = ({ distillery, logos, onClick, featured }) => {
  const distilleryStr = distillery.replace(/\s/g, "")
  return (
    <StyledLogo onClick={onClick} featured={featured}>
      <Img
        fluid={logos[`${distilleryStr}Logo`].fluid}
        alt={`${distilleryData[distillery].name} Logo`}
        imgStyle={{
          height: "100%",
          maxHeight: "200px",
          objectFit: "scale-down",
          imagePosition: "fill",
        }}
      />
    </StyledLogo>
  )
}

const StyledFeaturedDistillery = styled.div`
  max-width: 80vw;
  padding: 5vh 15vw 3vh 15vw;
  border-radius: 24px;
  background: white;
  -webkit-box-shadow: 16px 17px 64px -27px rgba(0, 0, 0, 0.68);
  -moz-box-shadow: 16px 17px 64px -27px rgba(0, 0, 0, 0.68);
  box-shadow: 16px 17px 64px -27px rgba(0, 0, 0, 0.68);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min-content;
  a {
    justify-self: center;
    width: min-content;
  }
  p {
    padding-top: 1rem;
  }
`

const FeaturedDistillery = ({ distillery, logos }) => (
  <StyledFeaturedDistillery>
    <a href={distilleryData[distillery].website}>
      <DistilleryLogo featured distillery={distillery} logos={logos} />
    </a>
    <p>{distilleryData[distillery].description}</p>
    <div className="socialIcons">
      {distilleryData[distillery].facebook && (
        <FBsvg href={distilleryData[distillery].facebook} />
      )}
      {distilleryData[distillery].instagram && (
        <IGsvg href={distilleryData[distillery].instagram} />
      )}
    </div>
  </StyledFeaturedDistillery>
)

export const SVGIcon = styled.svg`
  height: ${(props) => props.height || "32px"};
  opacity: 0.8;
  cursor: pointer;
  margin: 1em;
  &:hover {
    opacity: 0.6;
  }
  fill: ${(props) => props.color || "black"};
  transition: all 0.2s;
`

const FBsvg = ({ color, href }) => (
  <a href={href}>
    <SVGIcon
      data-name="FB Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.48 38"
    >
      <title>Simple Facebook Logo</title>
      <path
        d="M9.77,19.54v-.49c0-2.05,0-4.1,0-6.15,0-.43.14-.55.57-.54,1.22,0,2.44,0,3.67,0,.44,0,.59-.1.59-.56,0-1.42,0-2.84.1-4.25C15,3.65,17.34,1.22,21.33.73,23.1.51,24.9.56,26.68.51c.73,0,1.45,0,2.17,0,.29,0,.39.11.39.38q0,3.29,0,6.57c0,.34-.15.41-.46.41-1.44,0-2.89,0-4.33,0A2.24,2.24,0,0,0,22,10.05c-.05.74,0,1.48,0,2.28.19,0,.37,0,.55,0h6c.55,0,.55,0,.47.55q-.51,3.08-1,6.16a.51.51,0,0,1-.6.51c-1.62,0-3.24,0-4.85,0-.45,0-.59.12-.59.57,0,5.89,0,11.78,0,17.67,0,.68,0,.68-.68.68-2.05,0-4.09,0-6.13,0-.47,0-.61-.12-.6-.59q0-8.8,0-17.62c0-.71,0-.71-.71-.71-1.15,0-2.3,0-3.44,0C10.23,19.58,10,19.56,9.77,19.54Z"
        transform="translate(-9.76 -0.5)"
      />
    </SVGIcon>
  </a>
)

const IGsvg = ({ color, href }) => (
  <a href={href}>
    <SVGIcon
      data-name="IG Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
    >
      <title>Simple Instagram Logo</title>
      <path
        d="M19.35,38.47c-2.8,0-5.61.07-8.41,0A10.54,10.54,0,0,1,.61,27.8C.46,22.25.47,16.69.6,11.13.75,5.12,4.93,1,11,.74c4.47-.18,9-.29,13.43-.22a27.9,27.9,0,0,1,6.56.85,9,9,0,0,1,7,8.05A72.82,72.82,0,0,1,38.5,19,91.08,91.08,0,0,1,38,29.44a10,10,0,0,1-9.92,9c-2.9.12-5.81,0-8.71,0ZM19.43,5V5c-2.66,0-5.32-.07-8,0-3.78.13-6,2-6.53,5.18a24.5,24.5,0,0,0-.31,3.82c0,4.63.11,9.26.26,13.88a5.47,5.47,0,0,0,5.07,5.58,40.82,40.82,0,0,0,5.74.31c4.33,0,8.67,0,13-.31A5.36,5.36,0,0,0,34,28q.19-8.55,0-17.12C33.88,7.73,32.13,6,29,5.31A12.35,12.35,0,0,0,26.67,5C24.26,5,21.84,5,19.43,5Z"
        transform="translate(-0.5 -0.5)"
      />
      <path
        d="M19.41,10.45A9.52,9.52,0,1,1,10,20.1,9.38,9.38,0,0,1,19.41,10.45Zm0,4.29a5,5,0,0,0-5.21,5,5.15,5.15,0,0,0,5,5.45,5.32,5.32,0,0,0,5.26-5.11A5.11,5.11,0,0,0,19.44,14.74Z"
        transform="translate(-0.5 -0.5)"
      />
      <path
        d="M29.42,13a2.27,2.27,0,0,1-2.3-2.22,2.22,2.22,0,0,1,2.12-2.36,2.27,2.27,0,0,1,2.4,2.27A2.31,2.31,0,0,1,29.42,13Z"
        transform="translate(-0.5 -0.5)"
      />
    </SVGIcon>
  </a>
)

const DistilleriesSection = ({ logos }) => {
  const [featured, setFeatured] = useState("")
  return (
    <ContentSection id="distilleries">
      <CenteredDiv>
        <DistilleryLogoSection>
          {Object.keys(distilleryData).map((distillery) => {
            return (
              <DistilleryLogo
                logos={logos}
                key={distillery}
                distillery={distillery}
                onClick={() => setFeatured(distillery)}
              />
            )
          })}
          {featured && (
            <Modal
              open={true}
              colorOne={themeStyles.colorTwoRgba}
              colorTwo={themeStyles.colorThree}
              handleClose={() => setFeatured()}
            >
              <FeaturedDistillery distillery={featured} logos={logos} />
            </Modal>
          )}
        </DistilleryLogoSection>
      </CenteredDiv>
    </ContentSection>
  )
}

const DistilleriesSectionWrapper = () => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxHeight: 200, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imagesObject = {}
      const images = data.images.edges.filter((n) => {
        return n.node.relativePath.includes("distilleryLogos") // all files inside the distilleryLogos folder
      })
      images.forEach((n) => {
        imagesObject[n.node.name] = n.node.childImageSharp
      })
      return <DistilleriesSection logos={imagesObject} />
    }}
  />
)

export default DistilleriesSectionWrapper
