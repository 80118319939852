import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { themeStyles } from "./Page"
import HamburgerIcon from "./HamburgerIcon"
import StyledButton from "./styles/StyledButton"

const StyledNav = styled.nav`
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 2px;
  top: 2em;
  right: 0;
  a.active {
    color: ${props => props.colorTwo || "#2D97A0"};
  }
  a {
    margin: 0 2em;
    transition: color 0.3s;
    color: ${props => props.colorOne || "white"};
    &:hover {
      color: ${props => props.colorTwo || "#2D97A0"};
    }
  }
`

const MenuIcon = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  color: white;
  z-index: 12;
`

const MobileNavContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  transition: width 0.5s;
  width: ${props => (props.open ? "70vw" : "0vw")};
  background-color: rgba(38, 35, 40, 0.96);
  z-index: 10;
`

const MobileNav = styled.nav`
  position: relative;
  display: grid;
  align-items: center;
  text-align: right;
  padding-right: 20px;
  margin: 38vh 0;
  height: 24vh;
  width: 100%;
  font-size: 1.5em;
  z-index: 11;
`

const Links = ({ handleClick, mobile }) => (
  <>
    <Link to="/#distilleries" activeClassName="active" onClick={handleClick}>
      Distilleries
    </Link>
    <Link to="/#venue" activeClassName="active" onClick={handleClick}>
      Venue
    </Link>
    <Link to="/#buy" activeClassName="active" onClick={handleClick}>
      <StyledButton
        colorOne={themeStyles.colorThree}
        colorTwo={themeStyles.colorOne}
        width="200px"
        style={{ visibility: mobile && "hidden" }}
      >
        Buy Tickets
      </StyledButton>
    </Link>
  </>
)

class Navbar extends Component {
  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    this.setState({ windowWidth: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  handleResize = () => this.setState({ windowWidth: window.innerWidth })

  state = { open: false }

  render() {
    const { mobileBreakPoint, colorOne, colorTwo } = this.props
    const { windowWidth, open } = this.state
    return (
      <>
        {windowWidth < (mobileBreakPoint || 780) ? (
          <>
            <MenuIcon onClick={() => this.setState({ open: !this.state.open })}>
              <HamburgerIcon
                colorOne={colorOne}
                colorTwo={colorTwo}
                open={open}
              />
            </MenuIcon>
            <MobileNavContainer open={open}>
              <MobileNav>
                <Links handleClick={() => this.setState({ open: false })} />
              </MobileNav>
            </MobileNavContainer>
          </>
        ) : (
          <StyledNav colorOne={colorOne} colorTwo={colorTwo}>
            <Links mobile />
          </StyledNav>
        )}
      </>
    )
  }
}

export default Navbar
