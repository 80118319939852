import React from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"
import StyledButton from "../styles/StyledButton"

const StyledCookieBanner = styled.div`
  width: 100vw;
  min-height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${props => props.colorOne || "darkblue"};
  color: ${props => props.colorTwo || "white"};
  display: flex;
  @media (max-width: ${props => props.theme.mobileBreakPoint}px) {
    display: grid;
  }
  align-items: center;
  justify-content: space-between;
  padding: 2vh 5vw;
  a {
    margin: 0 18px;
  }
  button {
    justify-self: flex-end;
  }
  z-index: 1000000;
`

const CookieBanner = ({ colorOne, colorTwo }) => {
  const [cookies, setCookie] = useCookies(["acknowledgeCookies"])
  return (
    <>
      {!cookies.acknowledgeCookies && (
        <StyledCookieBanner colorOne={colorOne} colorTwo={colorTwo}>
          <p>
            This website uses cookies to ensure you get the best experience on
            our website.
            <a
              href="https://www.cookiesandyou.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </p>
          <StyledButton
            width="200px"
            colorOne="green"
            colorTwo="#06380c"
            onClick={() => {
              setCookie("acknowledgeCookies", true, { maxAge: 2600000 })
            }}
          >
            Got It
          </StyledButton>
        </StyledCookieBanner>
      )}
    </>
  )
}

export default CookieBanner
