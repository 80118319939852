import React from "react"
import styled from "styled-components"
import { SVGIcon } from "./DistilleriesSection"

const StyledFooter = styled.div`
  position: relative;
  background-color: ${props => props.backgroundColor};
  padding: 2em 0;
  width: 100vw;
  color: ${props => props.color};
  &::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(${props => props.backgroundImg});
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    z-index: 1;
  }
`

const CopyWright = styled.p`
  width: 100%;
  line-height: 0.8em;
  text-align: center;
  opacity: 0.8;
  font-size: 0.8em;
  letter-spacing: 3px;
`

const SpreadFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

const FooterContent = styled.div`
  position: relative;
  width: 100vw;
  margin: auto;
  z-index: 2;
  text-align: center;
  display: grid;
  height: 100%;
  grid-template-columns: 100vw;
  grid-template-rows: 2fr 1fr;
  align-items: center;
  justify-items: center;
`

const FooterBar = styled(SpreadFlex)`
  align-self: flex-end;
  opacity: 1;
  font-size: 0.8em;
  height: 100%;
  a {
    letter-spacing: 4px;
    margin: 0.5em;
  }
  @media (max-width: ${props => props.breakpoint}px) {
    flex-direction: column;
    align-content: space-between;
  }
`

const ToTopButtonContainer = styled.div`
  position: absolute;
  bottom: 0vw;
  right: 8vw;
  z-index: 10;
  opacity: 0.7;
  svg {
    transform: scale(1.5);
    margin: 0;
  }

  transition: all 0.3s;
`

const SocialIcon = styled.img`
  width: 32px;
  opacity: 0.8;
  cursor: pointer;
  margin: 1em;
  &:hover {
    transform: scale(1.15);
    opacity: 1;
  }
`

const ArrowSVG = ({ height, color, handleClick }) => {
  return (
    <SVGIcon
      id="upArrow"
      data-name="upArrow"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      onClick={handleClick}
      color={color}
    >
      <title>Scroll to Top</title>
      <path d="M.17,19.38A19.5,19.5,0,1,1,19.72,39,19.55,19.55,0,0,1,.17,19.38ZM21.9,32.49V17.64l.38-.24L26.77,22l3.38-3.32L19.62,8,9,18.61l3.86,3.81,4.69-5V32.49Z" />
    </SVGIcon>
  )
}

const Footer = ({
  children,
  backgroundImg,
  color,
  backgroundColor,
  copywright,
  breakpoint,
  facebook,
  instagram,
}) => {
  return (
    <StyledFooter
      backgroundImg={backgroundImg}
      color={color}
      backgroundColor={backgroundColor}
      id="footer"
    >
      <FooterContent>
        <FooterBar breakpoint={breakpoint}>
          {(facebook || instagram) && (
            <div>
              {facebook && (
                <a href={facebook}>
                  <SocialIcon
                    src="https://res.cloudinary.com/a1818/image/upload/v1551146255/socialIcons/facebookRoundWhite.png"
                    alt="instagram logo"
                  />
                </a>
              )}
              {instagram && (
                <a href={instagram}>
                  <SocialIcon
                    src="https://res.cloudinary.com/a1818/image/upload/v1551146255/socialIcons/instagramRoundWhite.png"
                    alt="facebook logo"
                  />
                </a>
              )}
            </div>
          )}
          {children}
        </FooterBar>
        <CopyWright>{copywright}</CopyWright>
        <ToTopButtonContainer>
          <ArrowSVG
            height="80px"
            color="white"
            handleClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }}
          />
        </ToTopButtonContainer>
      </FooterContent>
    </StyledFooter>
  )
}

export default Footer
