export const distilleryData = {
  "117 West Spirits": {
    name: "117° West Spirits",
    description:
      "Here at 117° West Spirits we aspire to produce high quality west coast-inspired spirits that reflect the innovative nature and laid back mentality of our native state - California.",
    address: "2332 La Mirada Drive Ste 800 Vista, CA 92081",
    website: "https://117westspirits.com",
    facebook: "https://www.facebook.com/117westspirits/",
    instagram: "https://www.instagram.com/117westspirits/",
    logoFileType: "jpg",
  },
  "619 Spirits": {
    name: "619 Spirits North Park",
    description:
      "619 distills locally and showcases both theirs and other locally crafted spirits at their conveniently located North Park tasting room.",
    address: "3015 Lincoln Ave San Diego, CA 92104",
    website: "https://www.619spirits.com/",
    facebook: "https://www.facebook.com/619Spirits/",
    instagram: "https://www.instagram.com/619vodka/",
    logoFileType: "png",
  },
  // "BNS Brewing And Distilling Co": {
  //   name: "BNS Brewing And Distilling Co",
  //   description:
  //     "BNS is both a craft brewery and distillery located in Santee.",
  //   address: "10960 Wheatlands Ave Santee, CA 92071",
  //   website: "https://bnsbrewing.com",
  //   facebook: "https://www.facebook.com/BNSBrewingDistillingCo/",
  //   instagram: "https://www.instagram.com/bnsbrewinganddistilling/",
  //   logoFileType: "png",
  // },
  // "California Spirits Company": {
  //   name: "California Spirits Company",
  //   description:
  //     "The California Spirits Company is a micro-Distillery in San Marcos, CA. We make whiskey, rum, vodka, gin, and specialty products. We offer tours of the facility, tastings, and alcohol production education.",
  //   address: "282 Enterprise St, Suite 104 San Marcos, CA 92078",
  //   website: "https://www.calspirits.com/home",
  //   facebook: "https://www.facebook.com/calspirits/",
  //   instagram: "https://www.instagram.com/calspirits/",
  //   logoFileType: "png",
  // },
  "Copper Collar Distillery": {
    name: "Copper Collar Distillery",
    description:
      "Copper Collar is located in Santee and specializes in craft vodkas and rums.",
    address: "8733 N Magnolia Ave. Suite 126 Santee, CA 92071",
    website: "https://coppercollardistillery.com/",
    // facebook: "https://www.facebook.com/coppercollardistillery/",
    instagram: "https://www.instagram.com/coppercollardistillery/",
    logoFileType: "png",
  },
  "Cutwater Spirits": {
    name: "Cutwater Spirits",
    description:
      "Whiskey, Vodka, Gin, Rum, Liqueur, mixers, canned cocktails and beyond. We’re always focused on the horizon, the road ahead. We’re just hardwired that way. Armed with some heavy experience, hard earned awards and boundless energy, we’re charging ahead to carve out our place in the global spirits industry. We invite all who share our passion to join us on the journey.",
    address: "9750 Distribution Ave San Diego, CA 92121",
    website: "https://cutwaterspirits.com/",
    facebook: "https://m.facebook.com/CWTastingRoomKitchen/",
    instagram: "https://www.instagram.com/cutwaterspirits/",
    logoFileType: "jpg",
  },
  "Henebery Spirits": {
    name: "Henebery Spirits",
    description:
      "Henebery Whiskey is A 90 proof rye whiskey infused with organic ingredients. Transforming the neat drinker's experience and enhancing innovative cocktails you will love.",
    address: "2870 Scott St #105 Vista, CA 92081",
    website: "https://www.heneberywhiskey.com/",
    facebook: "https://www.facebook.com/heneberywhiskey",
    instagram:
      "https://www.instagram.com/explore/locations/313215168825131/henebery-spirits/",
    logoFileType: "jpg",
  },
  "Humboldt Distillery": {
    name: "Humboldt Distillery",
    description:
      "At Humboldt Distillery, we aim to capture the spirit of California in its natural state. Surrounded by beautiful undeveloped coastline and old-growth redwood forests, we are ever mindful of our environmental impact. We prioritize organic ingredients and production methods because it is better for the environment, and we work to support efforts that preserve the coast and waterways throughout California for future generations.",
    address: "735 10th Street, Fortuna, CA 95540",
    website: "https://humboldtdistillery.com/",
    facebook: "https://www.facebook.com/HumboldtDistillery/",
    instagram: "https://www.instagram.com/humboldtdistillery/",
    logoFileType: "png",
  },
  "Joshua Tree Distilling Co": {
    name: "Joshua Tree Distilling Company",
    description:
      "Inspired by an insightful hike through Joshua Tree National Park, Joshua Tree Distilling Company was founded with the hope of creating exceptional beverages that mirror the equally exceptional desert landscape in which they are crafted.",
    address: "58945 Bus Center Dr Suite G, Yucca Valley, CA 92284",
    website: "https://www.joshuatreedistillingco.com",
    facebook: "https://www.facebook.com/josuatreedistillingco/",
    instagram: "https://www.instagram.com/joshuatreedistillingco/?hl=en",
    logoFileType: "png",
  },
  "Liberty Call Distilling Co": {
    name: "Liberty Call Distilling Co",
    description:
      "As premiere distillers, Liberty Call Distilling is master crafting spirits locally using quality ingredients indigenous to sunny California. Spirit lovers yearn for distinguished tastes that stand out from the rest and we have answered the call.",
    address: "2739 Via Orange Way, Suite 110 Spring Valley, CA 91978",
    website: "https://libertycalldistilling.com",
    facebook:
      "https://www.facebook.com/profile.php?id=166499140197949&ref=content_filter",
    instagram: "https://www.instagram.com/libertycalldistilling/",
    logoFileType: "png",
  },
  // "Los Californios Spirits": {
  //   name: "Los Californios Spirits",
  //   description:
  //     "Nestled on 6 acres in the Valle de Los Amigos on the outskirts of the San Diego backcountry, Los Californios is a craft artisanal distillery producing unique and innovative farm-to-flask spirits.  Los Californios spirits are created from their farm-grown ingredients and collaborations with other farms, wineries and breweries in California.",
  //   address: "1935 Orange Ave, County of San Diego, CA 92065",
  //   website: "https://www.loscaliforniosspirits.com",
  //   facebook: "https://www.facebook.com/FarmToFlask/",
  //   logoFileType: "png",
  // },
  "Malahat Spirits Co": {
    name: "Malahat Spirits Co",
    description:
      "We seek out extraordinary ingredients, combine traditional techniques with experimentation, and handcraft it all from scratch to make the finest spirits. We are proud of every drop and will only produce what we love to drink ourselves.",
    address: "8706 Production Ave San Diego, CA 92121",
    website: "https://www.malahatspirits.com/",
    facebook: "https://www.facebook.com/MalahatSpiritsCo/",
    instagram: "https://www.instagram.com/malahatspirits/",
    logoFileType: "png",
  },
  "Mike Curphy Distillery": {
    name: "Mike Curphy Distillery",
    description:
      'Mike Curphy Distillery is a small craft distillery founded in 2015. Our goal is to produce fine quality products for the more discriminating taste.  Our recipes come from Isle of Man (between the islands of Great Britain and Ireland) as told by Mike\'s grandfather. Mike is the eighth generation distiller of quality Uisge Beatha (pronounced "Ooshky Bayha") single malt whiskey.  We double distill all our liquors to achieve a smoother richer flavor in our products.',
    address: "2731 Via Orange Way, Suite 104 Spring Valley, CA 91978",
    website: "https://www.mcdistillery.com/",
    logoFileType: "jpeg",
  },
  // "Misadventure Spirits": {
  //   name: "Misadventure & Company",
  //   description:
  //     "It all started with a chance meeting at a North County San Diego bar between the two founders (Whit Rigali and Samuel Chereskin.) Whit, the bartender, wanted to create local craft spirits as tools for other bartenders. Sam, the Agricultural Economist, wanted to find ways to improve food systems and show that doing good can also be viable. Their conversation over a glass of whiskey led to the creation of Misadventure & Co. These spirits are not just products of 4 years of R&D in a cramped garage, but lessons learned through the San Diego brewing and distilling communities and our wild and wonderful friends and family. We desire to make quality spirits that everyone can enjoy and create them sustainably.",
  //   address: "",
  //   website: "https://www.misadventure.co/",
  //   facebook: "https://www.facebook.com/misadventureco",
  //   instagram: "https://www.instagram.com/misadventureco/?hl=en",
  //   logoFileType: "png",
  // },
  "Old Harbor Distilling Co": {
    name: "Old Harbor Distilling Co",
    description:
      "Handcrafted spirits distilled in the heart of downtown San Diego. Old Harbor makes unique flavorful liquor for any occasion.",
    address: "270 17th St. San Diego, CA 92101",
    website: "http://oldharbordistilling.com/",
    facebook: "https://www.facebook.com/OldHarborDistilling",
    instagram: "https://www.instagram.com/old_harbor/",
    logoFileType: "png",
  },
  "Pacific Coast Spirits": {
    name: "Pacific Coast Spirits",
    description:
      "We are a small batch artisan craft distillery, embodying the spirit and the essence of all that is California. We use local ingredients to create tasteful products that smell, taste and feel adventurous!",
    address: "404 S. Coast Hwy. Oceanside, Ca 92054",
    website: "https://paccoastspirits.com/",
    facebook: "https://www.facebook.com/PacificCoastSpiritsOside/",
    instagram: "https://www.instagram.com/pacificcoastspirits/",
    logoFileType: "png",
  },
  // "ReBru Spirits": {
  //   name: "Rebru Spirits",
  //   description:
  //     "ReBru Spirits was born out of the idea of doing more by wasting less. Their spirits are distilled from some of San Diego's best craft beers that were destined for the drain.",
  //   address: "1735 National Ave, San Diego, CA 92113",
  //   website: "https://rebruspirits.com/",
  //   facebook: "https://www.facebook.com/ReBruSpirits/",
  //   instagram: "https://www.instagram.com/rebruspirits/",
  //   logoFileType: "png",
  // },
  // "Perfect Soul": {
  //   name: "Perfect Soul",
  //   description:
  //     "From scratch we create 100 proof small batch rum and whiskey. We eliminate the bite, the burn, the aftertaste. The smoother the better. We appreciate a tasty full flavored, pure and clean, higher quality rum and whiskey. Deceivingly nice -- not just for anyone. We are happy to recommend a killer brewery that creates a light beer. We love life and we love all walks of life, Cheers Perfect Souls.",
  //   address: "215 S Pacific St #104 San Marcos, CA 92078",
  //   website: "http://www.perfectsoulwhiskey.com/",
  //   facebook: "https://www.facebook.com/PerfectSoulWhiskey/",
  //   instagram: "https://www.instagram.com/perfectsoulwhiskey/",
  //   logoFileType: "png",
  // },
  "San Diego Distillery": {
    name: "San Diego Distillery",
    description:
      "Our focus of distilling is to merge the world of craft beer with the world of craft whiskey. We use many non-standard malts in our recipes, but are common in the beer-brewing process. Using our specialized on-grain fermentation and distilling methods, the end result is a very unique set of whiskies.",
    address: "2733 Via Orange Way, Suite 101, Spring Valley CA 91978",
    website: "http://www.sddistillery.com/",
    facebook: "https://www.facebook.com/sandiegodistillery/",
    instagram: "https://www.instagram.com/sddistillery/",
    logoFileType: "jpg",
  },
  // "San Diego Sunshine Craft Spirits": {
  //   description:
  //     "One of the county's newest distilleries, San Diego Sunshine is located in the heart of wine country in Ramona, CA.",
  //   address: "432 Maplet St, Suite 6 Ramona, CA 92065",
  //   website: "http://www.sandiegosunshine.com/",
  //   facebook: "https://www.facebook.com/SanDiegoSunshineCo/",
  //   instagram: "https://www.instagram.com/san_diegosunshine/",
  //   logoFileType: "png",
  // },
  "Seven Caves Spirits": {
    name: "Seven Caves Spirits",
    description:
      "At Seven Caves Spirits, we believe in crafting the finest distilled spirits using the best ingredients possible. We don't cut corners. We don't take half measures. We are a true Grain to Glass San Diego distillery, which means every drop of alcohol that leaves our shop came to life here first.  We don't buy mass quantities of neutral spirits from bulk alcohol purveyors, run it through our still (or not), slap our name on it and call it good.",
    address: "7950 Stromesa Ct San Diego, CA 92126",
    website: "https://www.the7caves.com/",
    facebook: "https://www.facebook.com/7Caves/",
    instagram: "https://www.instagram.com/sevencaves/",
    logoFileType: "png",
  },
  "Shelter Distilling": {
    name: "Shelter Distilling",
    description:
      "A purpose driven distillery that passionately creates innovative, progressive spirits through methods that mirror the grand nature of the Eastern Sierra.",
    address: "100 Canyon Blvd #217, Mammoth Lakes, CA 93546",
    website: "https://shelterdistilling.com/",
    facebook: "https://www.facebook.com/shelterdistilling/",
    instagram: "https://www.instagram.com/shelterdistilling/",
    logoFileType: "png",
  },
  // "Shadow Ridge Spirits": {
  //   name: "Shadow Ridge Spirits Company",
  //   description: "",
  //   address: "3044 Industry St #107, Oceanside, CA 92054",
  //   website: "http://www.srdistilled.com/",
  //   facebook: "https://www.facebook.com/srdistilled/",
  //   instagram: "https://www.instagram.com/srdistilled/",
  //   logoFileType: "png",
  // },
  "Story House Spirits": {
    name: "Story House Spirits",
    description:
      "We founded Storyhouse Spirits on the belief that friendships grow together, share memories, and toast to our lives. While it looks like we’re just meticulously crafting small batch spirits in San Diego’s East Village, we’re secretly doing our part creating a more unified community. Bring your friends and make some new ones. Let’s all sip to our lives we share!",
    address: "1220 J St, San Diego, CA 92101",
    website: "https://storyhousespirits.com/",
    facebook: "https://www.facebook.com/StoryhouseSpiritsCA/?_rdc=1&_rdr",
    instagram: "https://www.instagram.com/storyhousespiritsca",
    logoFileType: "png",
  },
  // "Swan Bar": {
  //   name: "Swan Bar",
  //   description: "",
  //   website: "http://originalswanbar.com/",
  //   facebook: "https://www.facebook.com/originalswanbar/",
  //   instagram: "https://www.instagram.com/originalswanbar",
  //   logoFileType: "png",
  // },
  "You And Yours Distilling Co": {
    name: "You and Yours Distilling Co",
    description:
      "Tucked away in San Diego’s East Village, You & Yours Distilling Co. is a 2,300 sq foot, full production distillery, tasting room and event space, complete with a world-class cocktail program highlighting our current spirits offerings. Salvaged wood, whitewashed brick, exposed concrete, plush velvet, denim linen, copper and marble accents come together to create a fresh, unique and Insta-worthy atmosphere.",
    address: "1495 G St San Diego, CA 92101",
    website: "https://youandyours.com",
    facebook: "https://www.facebook.com/YouAndYoursSD/",
    instagram: "https://www.instagram.com/youandyourssd/",
    logoFileType: "jpg",
  },
  // SanDiegoVille: {
  //   name: "SanDiegoVille",
  //   website: "https://www.sandiegoville.com/",
  //   facebook: "https://www.facebook.com/sandiegoville/",
  //   logoFileType: "png",
  // },
}
